// 导入路由配置
import VueRouter from "vue-router";
import Vue from "vue";

// 使用路由
Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: { Auth: false, title: '首页' }
  },
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: { Auth: false, title: '登录' }
  },
  // 笔记
  {
    path: '/note',
    name: 'note',
    redirect: '/note/category',
    component: () => import('../views/note/index.vue'),
    children:[
      {
        path: 'category',
        name: 'noteCategory',
        component: () => import('../views/note/category.vue'),
        meta: { Auth: false, title: '分类详情' },
      },
      {
        path: 'details',
        name: 'details',
        component: () => import('../views/note/categoryDetails.vue'),
        meta: { Auth: false, title: '分类详情' },
      },
    ]
  },
  // 文章详情
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/note/article.vue'),
    meta: { Auth: false, title: '文章详情' }
  },
  // // 分类详情
  // {
  //   path: '/category',
  //   name: 'category',
  //   component: () => import('../views/note/note.vue'),
  //   meta: { Auth: false, title: '文章分类' },
  // },
  // 发布文章
  {
    path: '/publish',
    name: 'publish',
    component: () => import('../views/note/publish.vue'),
    meta: { Auth: false, title: '发布文章' }
  },
  // 相册
  {
    path: '/album',
    name: 'album',
    component: () => import('../views/album/index.vue'),
    meta: { Auth: false, title: '相册' },
    redirect: '/album/cover',
    children: [
      {
        path: 'cover',
        name: 'album-cover',
        component: () => import('../views/album/albumCover.vue'),
        meta: { Auth: false, title: '相册首页' },
      },
    ]
  },
  // 相册分类
  {
    path: '/albumcategory',
    name: 'albumcategory',
    component: () => import('../views/album/rotateImgs.vue'),
    meta: { Auth: false, title: '相册分类' },
  },
  // 相册详情
  {
    path: '/albumDetails',
    name: 'albumDetails',
    component: () => import('../views/album/albumDetails.vue'),
    meta: { Auth: false, title: '相册详情' },
  },
  // 动态
  {
    path: '/trends',
    name: 'trends',
    component: () => import('../views/trend/index.vue'),
    meta: { Auth: false, title: '相册详情' },
  },
  // 留言
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message/index.vue'),
    meta: { Auth: false, title: '留言' },
  },
  // 日志
  {
    path: '/log',
    name: 'log',
    component: () => import('../views/log/index.vue'),
    meta: { Auth: false, title: '更新日志' },
  },
  // 用户中心
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/index.vue'),
    meta: { Auth: true, title: '用户中心' },
    redirect: '/user/index',
    children: [
      {
        path: 'index',
        name: 'user-index',
        component: () => import('../views/user/home.vue'),
        meta: { Auth: false, title: '个人主页' },
      },
      {
        path: 'dynamic',
        name: 'user-dynamic',
        component: () => import('../views/user/dynamic.vue'),
        meta: { Auth: false, title: '个人动态' },
      },
      {
        path: 'album',
        name: 'user-album',
        component: () => import('../views/user/album.vue'),
        meta: { Auth: false, title: '个人相册' },
      },
      {
        path: 'collection',
        name: 'user-collection',
        component: () => import('../views/user/collection.vue'),
        meta: { Auth: false, title: '个人收藏' },
      },
      {
        path: 'setting',
        name: 'user-setting',
        component: () => import('../views/user/setting.vue'),
        meta: { Auth: false, title: '个人设置' },
        redirect: '/user/setting/baseInfo',
        children: [
          {
            path: 'baseInfo',
            name: 'user-setting-baseInfo',
            component: () => import('../views/user/setting/baseInfo.vue'),
            meta: { Auth: false, title: '个人资料' },
          },
          {
            path: 'privacy',
            name: 'user-setting-privacy',
            component: () => import('../views/user/setting/privacy.vue'),
            meta: { Auth: false, title: '隐私设置' },
          },
          {
            path: 'appearance',
            name: 'user-setting-appearance',
            component: () => import('../views/user/setting/appearance.vue'),
            meta: { Auth: false, title: '外观设置' },
          },
        ]
      },
    ]
  },
  // 管理端
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/index.vue'),
    redirect: '/album',
    // meta: { Auth: false, title: '后台管理' },
    children: [
      {
        path: '',
        name: '',
        component: () => import('../views/admin/home/index.vue'),
        meta: { Auth: false, title: '首页' },
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/admin/user/index.vue'),
        meta: { Auth: false, title: '用户列表' },
      },
      {
        path: 'article',
        name: 'article',
        component: () => import('../views/admin/article/index.vue'),
        meta: { Auth: false, title: '文章列表' },
      },
      {
        path: 'articleEdit',
        name: 'articleEdit',
        component: () => import('../views/admin/article/edit.vue'),
        meta: { Auth: false, title: '文章列表' },
      },
      {
        path: 'articleCategory',
        name: 'articleCategory',
        component: () => import('../views/admin/articleCategory/index.vue'),
        meta: { Auth: false, title: '分类列表' },
      },
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//全局守卫
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
  next();
});

// 解决路径重复的报错问题
//保存原型对象的Push
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

export default router;
