<template>
  <div id="app">
    <transition name="fade-main" mode="out-in">
      <router-view v-if="!$route.meta.link"/> 
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Joe Font", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, "sans-serif";
}
html{
  /* overflow-x: hidden; */
}
.wrap {
  width: 1200px;
  margin: 0 auto;
}
ul{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: #333;
}

html::-webkit-scrollbar {
  width: 8px; 
}
 
html::-webkit-scrollbar-track {
  background-color: 0 0;
}
 
html::-webkit-scrollbar-thumb {
  background: #c0c4cc;
  border-radius: 4px;
}
.line-background{
  background: linear-gradient(90deg, rgba(75, 75, 75, 0.08) 5%, rgba(215, 215, 215, 0.1) 0), 
  linear-gradient(rgba(75, 75, 75, 0.08) 5%, rgba(215, 215, 215, 0.1) 0);
  background-size: 20px 20px;
}
.unselectable {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none;    /* Firefox 2+ */
  -ms-user-select: none;     /* IE 10+ */
  user-select: none;         /* 标准语法 */
}
</style>
