import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import '@/assets/css/index.scss';

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

// icon图标
import '@/assets/icons'

import Prism from 'prismjs';
// 涟漪点击效果
import VWave from 'v-wave'

// 自定义指令
import "./utils/directives/highlight-directive.js";

// 弹幕效果

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VWave);

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
Vue.use(VueMarkdownEditor);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
